import './App.css';
import "rsuite/dist/rsuite.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Rutas } from './Rutas';
import { AuthProvider } from './utils/Auth';
import { ConfigProvider } from './utils/Config';
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import { WhatsAppBubble } from './components/whatsapp';



function App() {

  polyfillCountryFlagEmojis();

  return (
    <>
      <div className="navbar-page dark" data-theme={"dark"}>
        <AuthProvider>
          <ConfigProvider>
            <WhatsAppBubble>
              <Rutas />
            </WhatsAppBubble>
            
            
          </ConfigProvider>

        </AuthProvider>

      </div>
    </>
  );
}

export default App;
