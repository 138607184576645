import { useNavigate } from "react-router-dom";
import ModalSwalInformation from "../components/ModalSwalInformation";
import { UserMembership, UserProps, VideoProps } from "../types/videoTypes";
import { colors } from "../styles/patterns";
import { useTranslation } from "react-i18next";
import "../styles/icon.css";

const useGrantAccess = (
  currentUser: UserProps,
  videoProps?: VideoProps,
  onPause?: () => void
) => {
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  // Verifica si el usuario tiene una membresía activa
  const hasMembership = (): boolean => {
    if(currentUser){
      return currentUser?.id_membership !== UserMembership.FREE;
    }
    return false;
  };

  // Verifica si el usuario es SUPER_PRO
  const isSuperPro = (): boolean => {
    return currentUser?.id_membership === UserMembership.SUPER_PRO;
  };

  // Verifica si el usuario es administrador del club actual
  const isAdminThisClub = (): boolean => {
    return (
      currentUser?.id_membership === UserMembership.CLUB_ADMIN &&
      currentUser?.id_player === videoProps?.clubDataProps?.id_admin
    );
  };

  // Verifica si el usuario es administrador general
  const isAdmin = (): boolean => {
    return currentUser?.id_membership === UserMembership.ADMIN;
  };

  // Valida el acceso a videos exclusivos de SUPER_PRO
  const validateSuperProAccess = (): boolean => {
    console.log(
      "El video es exclusivo de SUPER_PRO?",
      videoProps.idEvent === 68
    );
    console.log("El usuario es SUPER_PRO?", isSuperPro());
    console.log("El usuario tiene membresía activa?", hasMembership());

    if (
      videoProps?.idEvent === 68 && // Verifica si el evento es exclusivo
      !isSuperPro() &&
      !isAdmin() &&
      !isAdminThisClub()
    ) {
      onPause(); // Pausa el video si está disponible
      ModalSwalInformation({
        title: t("Video.modals.super-pro-title", "Requiere Suscripción"),
        text: t(
          "Video.modals.super-pro-description",
          "Por favor suscríbete para acceder al contenido."
        ),
        iconHtml: `<img 
          src="/img/logo-fentech.png" 
          alt="Logo"
        />`,
        confirmButtonText: t("Video.Buttons.subscribe", "Suscribirse"),
        customClass: {
          icon: "no-border",
        },
        confirmButtonColor: colors.primary,
        showCancelButton: false,
        onConfirm: () => {
          navigate("/planessocios");
        },
      });
      console.log('Denied access');
      return false; // Deniega el acceso
    }
    console.log('Validación exitosa');
    return true; // Permite el acceso
  };

  return {
    hasMembership,
    isSuperPro,
    validateSuperProAccess,
  };
};

export default useGrantAccess;
