import Swal, { SweetAlertIcon } from "sweetalert2";

interface ModalSwalValues {
    icon?: SweetAlertIcon;
    iconHtml?: string;
    title: string;
    text:string;
    html?: string;
    didOpen?: () => void;
    preDeny?: () => void;
    showConfirmButton?: boolean;
    showCancelButton?: boolean;
    confirmButtonColor?: string;
    cancelButtonColor?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    timer?: number;
    customClass?: any;
    willOpenEnabled?: boolean;
    onConfirm?: () => void;
    onError?: () => void;
    willOpen?: () => void;
    MySwal?: typeof Swal;
  
  
  }
  
  
  const ModalSwalInformation = ({
    icon = 'info',
    iconHtml,
    title,
    text,
    html,
    didOpen,
    preDeny,
    customClass,
    showConfirmButton = true,
    showCancelButton = true,
    confirmButtonColor = '#4CAF50',
    cancelButtonColor ='#f44336',
    confirmButtonText ="Aceptar",
    cancelButtonText ="Cancelar",
    timer,
    onConfirm,
    willOpenEnabled,
    willOpen = willOpenEnabled ? (() => {Swal.showLoading(null)}) : null,
    onError,
  }:ModalSwalValues) =>{
    Swal.fire({
        title,
        text,
        icon,
        iconHtml,
        customClass,
        showConfirmButton,
        showCancelButton,
        confirmButtonColor,
        cancelButtonColor,
        confirmButtonText,
        cancelButtonText,
        timer,
        didOpen,
        preDeny,
        willOpen: willOpen ? ( () => {Swal.showLoading(null)}) : null,
        html,
    }).then((result) => {
        if(result.isConfirmed){
            onConfirm();
        }else {
          onError();
        }
    }).catch((error) =>{
      console.log('hubo un error con el modal',error);
    })
  }
  export default ModalSwalInformation;
  