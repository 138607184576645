import { faHouse, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SelectPicker } from "rsuite";
import { API_URL } from "../env";
import AuthContext from "../utils/Auth";
import { Get, getParameter, Post } from "../utils/consumir";
import Swal from "sweetalert2";
import ConfigContext from "../utils/Config";
import { useTranslation } from "react-i18next";
import {
  checkCamera,
  getDataClientUser,
  startRecordLiveVideo,
} from "../utils/Api/startLiveApi";
import useGrantAccess from "../hooks/useAccess";
import ModalSwalInformation from "../components/ModalSwalInformation";
import { colors } from "../styles/patterns";
import { set } from "react-hook-form";

export function StartLive() {
  const URL_API = API_URL || "";
  const tokenID = "sportpro-token-v2";

  const { currentUser, GetTokenLogin, GetUser } = useContext(AuthContext);

  const refprivate = useRef<any>();
  const refduracion = useRef<any>();
  const [valueSelectHour, setValueSelectHour] = useState("40");
  const [valueSelecprivate, setValueSelectprivate] = useState("false");
  const { isSuperPro, hasMembership } = useGrantAccess(currentUser);

  const { GetConfig } = useContext(ConfigContext);
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [start, setStart] = useState(false);
  const [data, setData] = useState<any>({
    duration: "40",
    private: "false",
    describe: `${t("StartLive.form.describe")}`,
  });

  const idioma = {
    placeholder: `${t("CreateHL.selectCat")}`,
    searchPlaceholder: `${t("Home.Filter.Search")}`,
    noResultsText: `${t("Home.Filter.NoResult")}`,
  };

  const duration = [
    // {
    //     label: "30 " + `${t("StartLive.selected.minute")}` + " "+`${t("StartLive.selected.free")}`,
    //     value: "30",
    // },
    {
      label:
        "40 " +
        `${t("StartLive.selected.minute")}` +
        " " +
        `${t("StartLive.selected.free")}`,
      value: "40",
    },
    {
      label:
        "1 " +
        `${t("StartLive.selected.hour")}` +
        " " +
        `${t("StartLive.selected.member")}`,
      value: "60",
    },
    {
      label:
        "1 " +
        `${t("StartLive.selected.hour")}` +
        " 30 " +
        `${t("StartLive.selected.minute")}` +
        " " +
        `${t("StartLive.selected.member")}`,
      value: "90",
    },
    {
      label:
        "2 " +
        `${t("StartLive.selected.hour")}` +
        " 30 " +
        `${t("StartLive.selected.minute")}` +
        " " +
        `${t("StartLive.selected.super-pro")}`,
      value: "150",
    },
  ];

  const privatevideo = [
    {
      label: `${t("CreateHL.modals.public")}   ${t("StartLive.selected.free")}`,
      value: "false",
    },
    {
      label: `${t("CreateHL.modals.private")}   ${t(
        "StartLive.selected.member"
      )}`,
      value: "true",
    },
  ];

  useEffect(() => {
    getDataUser();
  }, []);

  const getDataUser = () => {
    const token = localStorage.getItem(tokenID);
    if (token) {
      try {
        setLoading(true);
        const paramVideo = getParameter("video");
        const value = {
          token: token,
        };
        getDataClientUser(value)
          .then((resp: any) => {
            if (resp) {
              setLoading(false);
              checkCamera(paramVideo)
                .then((resp: any) => {
                  if (resp.status === 200) {
                    const result = resp.data;
                    setData({
                      ...data,
                      datosclub: result.club,
                    });
                  } else {
                    navigate("/");
                  }
                })
                .catch((err: any) => {
                  console.error(
                    "Hubo un error al  revisar los datos de la camara",
                    err
                  );
                });
            }
          })
          .catch((err) => {
            console.error("Hubo un erro al traer los datos del usuario", err);
          });
      } catch (error) {
        let video = getParameter("video");
        navigate("/login", {
          state: {
            video: video,
          },
        });
        setLoading(false);
      }
    } else {
      let video = getParameter("video");
      navigate("/login", {
        state: {
          video: video,
        },
      });
      setLoading(false);
    }
  };

  const checkPermissions = () => {
    if (!isSuperPro() && Number(valueSelectHour) > 90) {
      ModalSwalInformation({
        title: t("Video.modals.super-pro-title", "Requiere Suscripción"),
        text: t(
          "Video.modals.super-pro-startVideo-description",
          "Por favor suscríbete para acceder a la funcionalidad."
        ),
        iconHtml: `<img 
          src="/img/logo-fentech.png" 
          alt="Logo"
        />`,
        confirmButtonText: t("Video.Buttons.subscribe", "Suscribirse"),
        customClass: {
          icon: "no-border",
        },
        confirmButtonColor: colors.primary,
        showCancelButton: false,
        onConfirm: () => {
          navigate("/planessocios");
        },
        onError: () => {
          setDisabledBtn(false);
        },
      });
      return false;
    } else if (!hasMembership && Number(valueSelectHour) > 40) {
      Swal.fire({
        title: `${t("SuscriptionClub.Subscribe")}`,
        text: `${t("Video.pro")}`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: `${t("Video.subme")}`,
        cancelButtonText: `${t("Video.keepFree")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/planessocios");
        } else {
          setDisabledBtn(false);
        }
      });
      return false;
    }
    return true;
  };

  const startLiveRecord = () => {
    const values = {
      time: data.duration,
      isPrivate: data.private,
      description: data.describe,
      codecamera: getParameter("video"),
      token: GetTokenLogin(),
    };
    if (checkPermissions()) {
      startRecordLiveVideo(values)
        .then((resp: any) => {
          const result = resp.data;
          if (resp.status) {
            console.log("que trae result de recordVideo?", result);
            GetConfig(true);
            let timerInterval;
            Swal.fire({
              title: `${t("StartLive.modals.startStream")}`,
              html: `${t("StartLive.modals.html")}` + "<b></b>",
              icon: "success",
              timer: 15000,
              didOpen: () => {
                Swal.showLoading(null);
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
                }, 100);
              },
            }).then(() => {
              setDisabledBtn(false);

              navigate("/v?id=" + result.id);
            });
          } else {
            Swal.fire(
              `${t("Suscriptions.ModalMessages.errorMessage")}`,
              `${t("Suscriptions.ModalMessages.description2")}` +
                result.message,
              "error"
            );
            if (result.streamID) {
              GetConfig(true);
              navigate("/v?id=" + result.streamID);
            }
            setDisabledBtn(false);
          }
        })
        .catch((err: any) => {
          console.error("Hubo en un error al iniciar la grabación", err);
          setDisabledBtn(false);
          debugger;
          Swal.fire(
            `${t("Suscriptions.ModalMessages.errorMessage")}`,
            err,
            "error"
          );
        });
    }
  };

  //   const GetUserData = async () => {
  //     const token = localStorage.getItem(tokenID);

  //     if (token) {
  //       try {
  //         let res = await Post(URL_API + "/login/getDataClient", {
  //           token: token,
  //         });
  //         Get(URL_API + "/recordvideo/checkcamera/" + getParameter("video")).then(
  //           (res) => {
  //             setLoading(false);

  //             if (res.data.status) {
  //               setData({
  //                 ...data,
  //                 datosclub: res.data.club,
  //               });
  //             } else {
  //               navigate("/");
  //             }
  //           }
  //         );
  //       } catch (e) {
  //         let video = getParameter("video");
  //         navigate("/login", {
  //           state: {
  //             video: video,
  //           },
  //         });
  //         setLoading(false);
  //       }
  //     } else {
  //       let video = getParameter("video");
  //       navigate("/login", {
  //         state: {
  //           video: video,
  //         },
  //       });
  //       setLoading(false);
  //     }
  //   };

  //   useEffect(() => {
  //     setLoading(true);
  //     const fetchData = async () => {
  //       await GetUserData();
  //     };

  //     fetchData();
  //   }, []);

  if (!start) {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <button
            onClick={() => {
              navigate("/");
            }}
            className="btn btn-success"
            style={{
              marginTop: "100px",
              fontSize: "20px",
              width: "300px",
            }}
          >
            <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faHouse} />
            {t("StartLive.buttons.goBack")}
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              setStart(true);
            }}
            style={{
              marginTop: "50px",
              fontSize: "20px",
              width: "300px",
            }}
          >
            <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faVideo} />

            {t("StartLive.buttons.initStream")}
          </button>
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{
          padding: "20px",
        }}
      >
        {loading ? (
          <div className="cssload-loader">
            <div className="cssload-inner cssload-one"></div>
            <div className="cssload-inner cssload-two"></div>
            <div className="cssload-inner cssload-three"></div>
          </div>
        ) : (
          <>
            <h4>
              <b> {t("StartLive.sportingClub")} </b>{" "}
              {data.datosclub !== undefined ? data.datosclub.displayname : ""}
            </h4>
            <br></br>
            <div className="mb-6">
              <label className="form-label fw-bold">
                {" "}
                {t("StartLive.form.duration")}
              </label>
              <div className="d-flex">
                <SelectPicker
                  ref={refduracion}
                  locale={idioma}
                  value={valueSelectHour}
                  defaultValue={data.duration}
                  searchable={false}
                  onChange={(value: any) => {
                    if (currentUser.id_membership === 1) {
                      if (value > 40) {
                        setValueSelectHour("40");
                        Swal.fire({
                          title: `${t("SuscriptionClub.Subscribe")}`,
                          text: `${t("Video.pro")}`,
                          icon: "info",
                          showCancelButton: true,
                          confirmButtonText: `${t("Video.subme")}`,
                          cancelButtonText: `${t("Video.keepFree")}`,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            navigate("/planessocios");
                          }
                        });
                        // refduracion.current.setState({ value: 40 });
                      } else {
                        setValueSelectHour(value);
                      }
                    } else if (value > 90) {
                      if (!isSuperPro()) {
                        ModalSwalInformation({
                          title: t(
                            "Video.modals.super-pro-title",
                            "Requiere Suscripción"
                          ),
                          text: t(
                            "Video.modals.super-pro-startVideo-description",
                            "Por favor suscríbete para acceder a la funcionalidad."
                          ),
                          iconHtml: `<img 
                            src="/img/logo-fentech.png" 
                            alt="Logo"
                          />`,
                          confirmButtonText: t(
                            "Video.Buttons.subscribe",
                            "Suscribirse"
                          ),
                          customClass: {
                            icon: "no-border",
                          },
                          confirmButtonColor: colors.primary,
                          showCancelButton: false,
                          onConfirm: () => {
                            navigate("/planessocios");
                          },
                        });
                      } else {
                        setValueSelectHour("150");
                        setData({ ...data, duration: "150" });
                      }
                    } else {
                      setValueSelectHour(value);
                      setData({ ...data, duration: value });
                    }
                  }}
                  data={duration}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="mb-6">
              <label className="form-label fw-bold">
                {" "}
                {t("StartLive.form.viewerStream")}
              </label>
              <div className="d-flex">
                <SelectPicker
                  ref={refprivate}
                  locale={idioma}
                  value={valueSelecprivate}
                  defaultValue={data.private}
                  searchable={false}
                  onChange={(value: any) => {
                    if (currentUser.id_membership === 1) {
                      //console.log("🚀 ~ file: startlive.tsx ~ line 228 ~ StartLive ~ value", value);
                      if (value === "true") {
                        setValueSelectprivate("false");
                        Swal.fire({
                          title: `${t("SuscriptionClub.Subscribe")}`,
                          text: `${t("Video.pro")}`,
                          icon: "info",
                          showCancelButton: true,
                          confirmButtonText: `${t("Video.subme")}`,
                          cancelButtonText: `${t("Video.keepFree")}`,
                        })
                          .then((result) => {
                            if (result.isConfirmed) {
                              navigate("/planessocios");
                            }
                          })
                          .finally(() => {
                            refprivate.current.setState({ value: "false" });
                            setData({ ...data, private: "false" });
                          });
                      }
                    } else {
                      setValueSelectprivate(value);
                      setData({ ...data, private: value });
                    }
                  }}
                  data={privatevideo}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="mb-10">
              <label className="form-label fw-bold">
                {" "}
                {t("StartLive.form.description")}
              </label>
              <div className="d-flex">
                <textarea
                  className="form-control"
                  maxLength={80}
                  rows={5}
                  onChange={(e: any) => {
                    setData({ ...data, describe: e.target.value });
                  }}
                  value={data.describe}
                />
              </div>
              <span>
                {data.describe !== undefined && data.describe !== null
                  ? data.describe.length
                  : 0}
                /80
              </span>
            </div>
            <div className="mb-10">
              <button
                disabled={disabledBtn}
                style={{
                  fontSize: "20px",
                }}
                className="btn btn-danger form-control"
                onClick={() => {
                  setDisabledBtn(true);
                  if (
                    data.describe === undefined ||
                    data.describe === "" ||
                    data.describe.length < 10 ||
                    data.describe.length > 80
                  ) {
                    Swal.fire({
                      title: "Error",
                      text: `${t("StartLive.errorDescription")}`,
                      icon: "error",
                    });
                    setDisabledBtn(false);

                    return;
                  }
                  //console.log(data.duration);
                  startLiveRecord();
                  //   Post(URL_API + "/recordvideo/startRecord", {
                  //     time: data.duration,
                  //     isPrivate: data.private,
                  //     description: data.describe,
                  //     codecamera: getParameter("video"),
                  //     token: GetTokenLogin(),
                  //   })
                  //     .then((res) => {
                  //       if (res.data.status) {
                  //         GetConfig(true);
                  //         let timerInterval;
                  //         const MySwal: any = Swal;

                  //         MySwal.fire({
                  //           title: `${t("StartLive.modals.startStream")}`,
                  //           html: `${t("StartLive.modals.html")}` + "<b></b>",
                  //           icon: "success",
                  //           timer: 15000, //!cambiar este valor
                  //           didOpen: () => {
                  //             MySwal.showLoading(null);
                  //             const b =
                  //               MySwal.getHtmlContainer().querySelector("b");
                  //             timerInterval = setInterval(() => {
                  //               b.textContent = (
                  //                 MySwal.getTimerLeft() / 1000
                  //               ).toFixed(0);
                  //             }, 100);
                  //           },
                  //         }).then(() => {
                  //           setDisabledBtn(false);

                  //           navigate("/v?id=" + res.data.id);
                  //         });
                  //       } else {
                  //         Swal.fire(
                  //           `${t("Suscriptions.ModalMessages.errorMessage")}`,
                  //           `${t("Suscriptions.ModalMessages.description2")}` +
                  //             res.data.message,
                  //           "error"
                  //         );
                  //         if (res.data.streamID) {
                  //           GetConfig(true);
                  //           navigate("/v?id=" + res.data.streamID);
                  //         }
                  //         setDisabledBtn(false);
                  //       }
                  //     })
                  //     .catch((err) => {
                  //       setDisabledBtn(false);
                  //       debugger;
                  //       Swal.fire(
                  //         `${t("Suscriptions.ModalMessages.errorMessage")}`,
                  //         err,
                  //         "error"
                  //       );
                  //     });
                }}
              >
                <FontAwesomeIcon icon={faVideo} />{" "}
                {t("StartLive.buttons.initStream")}
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
}
