export interface UserProps {
    id_player:number;
    id_membership:number;
    email:string;
}

export const UserMembership = {
    FREE: 1,
    PRO: 2,
    CLUB_ADMIN: 3,
    COACH: 4,
    ADMIN: 0,
    SUPER_PRO: 5,
}

export type PlanSelected = {
    idplan: string;
    subscriptionNumber?: number;
  };

export interface UserDataProps {
    aceptinfo:boolean;
    aceptnotif:boolean;
    aceptterms:boolean;
    created_at:string;
    email:string;
    firstname?:string;
    flag?:string;
    id:number;
    id_ascoach?:number;
    id_country:number;
    id_membership:UserProps['id_membership'];
    id_sport?:number;
    iscoach?:boolean;
    lastname?:string;
    nickname?:string;
    photoicon?:string;
    provider?:string;
    uidfirebase?:string;
    updated_at?:string;
    username?:string;
}

export interface ClubDataProps{
    id:number;
    clubname:string;
    clubaddress:string;
    countryicon:string;
    id_admin:number;
    timezone:number;
}

export interface SportProps{
    id:number;
    sportname:string;
}

export interface VideoProps {
    cameraData:string;
    clubDataProps:ClubDataProps;
    description:string;
    dislike:number;
   endtime:string;
    id:string;
    id_camera:number;
    idEvent:number;
    id_highlightcat:string;
    id_player:number;
    initialtime:string;
    ishighlight:boolean;
    isLive:boolean;
    isprivate:boolean;
    isrecorded:boolean;
    like:number;
    photopath:string;
    playingtime:number;
    sportData:SportProps;
    streamingurl:string;
    taggedUsers:string[];
    userdata:UserDataProps;
    videopath:string;
    views:number;
}
export interface SponsorsProps {
    buy_btwVideos?:boolean;
    buycarrusel_top?:boolean;
    buyvideo_ads?:boolean;
    countDownImages?:number[];
    country:string;
    endDate:string;
    id:number;
    id_club:number;
    image_btw_videos_path?:string[];
    image_carruseltop_path?:string[];
    image_other_path?:string[];
    isactive:boolean
    language:string;
    sponsorInvoiceDay?:number;
    sponsor_brand?:string;
    sponsor_contact?:string;
    sponsor_documentDv?:string;
    sponsor_documentNumber?:number;
    sponsor_email?:string;
    sponsor_name?:string;
    sponsor_phone?:string;
    sponsor_url?:string;
    sponsor_view_bannerBottomVideo?:number;
    sponsor_view_bannerHome?:number;
    sponsor_view_videoAds?:number;
    sponsorview?:number;
    startData:Date;
    status:'ACTIVE'|'INACTIVE'|'PENDING';
    tagged_clubs?:string[];
    videos_adspath:string[];

}

export interface SaveDataHL {
    from:number;
    to:number;
    description:string;
    isPrivate:boolean;
    category:string;
    duration:number;
}
export interface CreateHighLightVideoProps {
    cameraId:VideoProps['id_camera'];
    adsEnable:boolean;
    videoOnplay:VideoProps['videopath'];
}