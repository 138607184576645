export const API_URL_LOCAL_DEV = "http://localhost:3100";

// export const API_URL = "https://e038-201-214-213-9.ngrok.io"
export const API_URL = "https://backendsportprotv-p456tcdk5q-ue.a.run.app";
export const API_URL_DEV = "https://backendsportprotvdev-p456tcdk5q-ue.a.run.app";
export const REACT_APP_MODE = "PROD";
export const REACT_APP_API_PRINCIPAL = "http://localhost:3100";
export const REACT_APP_API_URL = "https://backendsportprotv-p456tcdk5q-ue.a.run.app";
export const REACT_APP_API_URL_DEV = "https://backendsportprotvdev-p456tcdk5q-ue.a.run.app";
export const REACT_APP_SALA_1 = "https://www.sportpro.tv/startlive?video=2BXT4OF5G0";
export const APP_GOOGLE_ADS_CLIENTID = "ca-pub-4195577514052158";
export const APP_GOOGLE_ADS_SLOT = "7372064261";
export const APP_TOKEN_SPORTPRO = "sportpro-token-v2";
export const APP_GOOGLE_TAG = "GTM-M7799BZ";
export const REACT_APP_ENV_STATUS_DEV = false;
export const REACT_APP_PAYPAL_CLIENT_ID ="AQt-joggUEmfbqAIltaMjBt7Ix90JFA5NeLIKGZxiC1wUfttTJmG0k9xgEaX8j79IsWkbqSwB2UtGaJd";
export const REACT_APP_PLAN_PRO = "P-7TS6228264587662WMNZNM3Q";
export const REACT_APP_PLAN_SUPERPRO = "P-7SX33647W3830091PM5UNRNI";
export const REACT_APP_PLAN_SUPERPRO_ANNUAL = "P-9FK66364MM6786228M53S4NA";
export const REACT_APP_PLAN_CLUB_OWNER = "P-0YT0933278444310WMN5WWQQ";
export const REACT_APP_PLAN_CLUB_RENT = "P-9JU480136A4467600MN5WYBY";
export const REACT_APP_PLAN_CLUB_CONNECT = "P-4B573197AK0327748MN5WZEA";
