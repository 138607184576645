import { faCancel, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useContext } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PayPalPopUp } from "../components/paypalpopup";
import AuthContext from "../utils/Auth";
import "./planessocios.css";
import { useTranslation } from "react-i18next";
import { PlanSelected, UserMembership } from "../types/videoTypes";
import { REACT_APP_PAYPAL_CLIENT_ID, REACT_APP_PLAN_PRO, REACT_APP_PLAN_SUPERPRO, REACT_APP_PLAN_SUPERPRO_ANNUAL } from "../env";

//console.log("En qué estado estamos?", REACT_APP_MODE);

export default function Planessocios() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [popup, setPopup] = React.useState(false);
  const [planSelected, setPlanSelected] = React.useState<PlanSelected>({
    idplan: "",
    subscriptionNumber: null,
  });

  const planPro = [
    {
      title: `${t("Suscriptions.Sucriptions_Pro.TrainingTools")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.FromTo")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.Transmitions")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.ShareIn")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.PrivateAndPublic")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.CreateHL")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.ShareWithCoach")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.Tagged_users")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.NoAdsVideos")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.Download")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.Storage2")}`,
      icon: faCheckCircle,
      include: true,
    },
    //{
    //  title: `${t("Suscriptions.Sucriptions_Pro.MarkBelong")}`,
    //  icon: faCancel,
    //  include: false,
    //},
  ];
  const planFree = [
    {
      title: `${t("Suscriptions.Sucriptions_Pro.TrainingTools")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Suscription_free.FromTo")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscription_free.Transmitions")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscription_free.ShareIn")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscription_free.PrivateAndPublic")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Suscription_free.CreateHL")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Suscription_free.ShareWithCoach")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Suscription_free.Tagged_users")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Suscription_free.NoAdsVideos")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Suscription_free.Download")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Suscription_free.Storage2")}`,
      icon: faCheckCircle,
      include: true,
    },
  ];
  const planSuperPro = [
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.TrainingTools")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.FromTo")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.Transmitions")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.ShareIn")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.PrivateAndPublic")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.CreateHL")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.ShareWithCoach")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.Tagged_users")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.NoAdsVideos")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.Download")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.Storage2")}`,
      icon: faCheckCircle,
      include: true,
    },
    //{
    //  title: `${t("Suscriptions.Suscriptions_SuperPro.MarkBelong")}`,
    //  icon: faCheckCircle,
    //  include: true,
    //},
  ];

  const handleSuscription = () => {
    switch (currentUser?.id_membership) {
      case UserMembership.FREE:
        return "free";
      case UserMembership.PRO:
        return "pro";
      case UserMembership.SUPER_PRO:
        return "super-pro";
      default:
        return "unknown";
    }
  };
  
  return (
    <div className="container">
      <div className="mt-5 row">     
      <div className="col-md-4">
          <div className="text-white card card-pricing card-raised container-plan-pro">
            <div className="bg-red-800 rounded-lg card-body ">
              <div
                style={{
                  height: "80px",
                }}
              >
                <span
                  className="absolute rounded-full shadow-lg right-1 top-6 laptop:top-4 laptop:right-1"
                >
                 <img src="/img/logo-fentech.png" alt="fentech" className="rounded-full w-[3rem] h-[3rem] laptop:w-[4rem] laptop:h-[4rem] object-cover"/>
                </span>

                <h5 className="card-category text-titulo-pro">
                  {t("Suscriptions.suscriptionSuperPro")}
                </h5>
                <div className="flex flex-row items-center justify-center">
                  <small className="text-base font-medium text-white">
                    USD
                  </small>
                  <small className="mx-1 text-xl font-semibold text-white">
                    9,50
                  </small>
                  <small className="text-base font-medium text-white">
                    / {t("Suscriptions.month")}
                  </small>
                  <small className="mx-2 font-bold text-white"> O </small>
                  <small className="text-base font-medium text-white">
                    USD
                  </small>
                  <small className="mx-1 text-xl font-semibold text-white">
                    69,90
                  </small>
                  <small className="text-base font-medium text-white">
                    / {t("Suscriptions.anual")}
                  </small>
                </div>
               
              </div>
              <div className="mt-3 border-white row border-bottom"></div>
              {planSuperPro.map((item, index) => {
                const isLastItem = index === planPro.length - 1;
                return (
                  <React.Fragment key={index + item.title}>
                    <div className="flex flex-row items-center justify-start my-3">
                      <div className="flex items-center justify-center mr-4 ">
                        <FontAwesomeIcon
                          className={
                            "icono-planes-socios-ok " +
                            (item.include ? "ok-gratis" : "cancel")
                          }
                          icon={item.icon}
                        />
                      </div>
                      <div>
                        <p
                          className="text-planes-socios"
                          style={{
                            color: "white",
                          }}
                        >
                          {item.title}
                        </p>
                      </div>
                    </div>
                    {!isLastItem && (
                      <div className="mt-3 border-white row border-bottom"></div>
                    )}
                  </React.Fragment>
                );
              })}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {
                  // currentUser ?
                  <div>
                    {/* {currentUser ? (
                  <div>
                    {currentUser?.id_membership !== 1 ? (
                      <button
                        className="px-3 py-3 text-base font-medium text-white bg-black rounded-md"
                        disabled
                      >
                        {t("Suscriptions.suscribeOnready")}
                      </button>
                    ) : (
                      <button
                        className="px-3 py-3 text-base font-medium text-white bg-black rounded-md"
                        onClick={() => {
                          setPopup(true);
                        }}
                      >
                        {t("Suscriptions.suscribe")}
                      </button>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      onClick={() => {
                        navigate("/login");
                      }}
                      className="text-lg font-semibold text-center cursor-pointer hover:text-red-300"
                    >
                      {t("SuscriptionClub.NeedsLogin")}
                    </a>
                  </div>
                )} */}

                    {currentUser ? (
                      <div>
                        {handleSuscription() === "super-pro"? (
                          <button
                            className="px-3 py-3 text-base font-medium text-white bg-black rounded-md"
                            disabled
                          >
                            {t("Suscriptions.suscribeOnready")}
                          </button>
                        ) : (
                          <div className="flex flex-col items-center justify-center gap-4">
                          <button
                            className="p-4 text-base font-medium text-white bg-black rounded-md hover:bg-red-500"
                            onClick={() => {
                              setPopup(true);
                              setPlanSelected({
                                idplan: REACT_APP_PLAN_SUPERPRO,
                                subscriptionNumber: UserMembership.SUPER_PRO,
                              });
                            }}
                          >
                            {t("Suscriptions.suscribeMonthly")}
                          </button>
                          <button
                            className="p-4 text-base font-medium text-black bg-white rounded-md hover:bg-red-500 hover:text-white"
                            onClick={() => {
                              setPopup(true);
                              setPlanSelected({
                                idplan: REACT_APP_PLAN_SUPERPRO_ANNUAL,
                                subscriptionNumber: UserMembership.SUPER_PRO,
                              });
                            }}
                          >
                            {t("Suscriptions.suscribeAnnual")}
                          </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          onClick={() => {
                            navigate("/login");
                          }}
                          className="text-lg font-semibold text-center cursor-pointer hover:text-red-300"
                        >
                          {t("SuscriptionClub.NeedsLogin")}
                        </p>
                      </div>
                    )}
                    {/* <button
                      className="px-3 py-3 text-base font-medium text-white bg-black rounded-md opacity-60"
                    >
                      {t("Suscriptions.Comming")}
                    </button> */}
                  </div>
                  // :
                  // <div
                  //   style={{£
                  //     display: "flex",
                  //     justifyContent: "center",
                  //     alignItems: "center",
                  //   }}
                  // >
                  //   <a
                  //     style={{
                  //       fontSize: '21px',
                  //     }}
                  //     onClick={() => {
                  //       navigate('/login');
                  //     }}
                  //     className="htitle">Debes iniciar sesión para subscribirte.</a>
                  // </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="text-white card card-pricing card-raised container-plan-pro">
            <div className="bg-red-800 rounded-lg card-body">
              <div
                style={{
                  height: "80px",
                }}
              >
                <h5 className="card-category text-titulo-pro">
                  {t("Suscriptions.suscripcionPro")}
                </h5>
                <h5 className="card-category text-titulo-pro"></h5>
                <div className="flex flex-row items-center justify-center">
                  <small className="text-base font-medium text-white">
                    USD
                  </small>
                  <small className="mx-1 text-xl font-semibold text-white">
                    2,9
                  </small>
                  <small className="text-base font-medium text-white">
                    /{t("Suscriptions.month")}
                  </small>
                </div>
              </div>
              <div className="mt-3 border-white row border-bottom"></div>
              {planPro.map((item, index) => {
                const isLastItem = index === planPro.length - 1;
                return (
                  <>
                    <div
                      key={index}
                      className="flex flex-row items-center justify-start my-3"
                    >
                      <div className="flex items-center justify-center mr-4 ">
                        <FontAwesomeIcon
                          className={
                            "icono-planes-socios-ok " +
                            (item.include ? "ok-gratis" : "cancel")
                          }
                          icon={item.icon}
                        />
                      </div>
                      <div>
                        <p
                          className="text-planes-socios"
                          style={{
                            color: "white",
                          }}
                        >
                          {item.title}
                        </p>
                      </div>
                    </div>
                    {!isLastItem && (
                      <div className="mt-3 border-white row border-bottom"></div>
                    )}
                  </>
                );
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                className="mt-3"
              >
                {currentUser ? (
                  <div>
                    {handleSuscription() !== 'free' && handleSuscription() === 'pro' ? (
                      <button
                        className="px-3 py-3 text-base font-medium text-white bg-black rounded-md"
                        disabled
                      >
                        {t("Suscriptions.suscribeOnready")}
                      </button>
                    ) : (
                      <button
                        className="px-3 py-3 text-base font-medium text-white bg-black rounded-md"
                        onClick={() => {
                          setPopup(true);
                          setPlanSelected({
                            idplan: REACT_APP_PLAN_PRO,
                            subscriptionNumber: UserMembership.PRO,
                          });
                        }}
                      >
                        {t("Suscriptions.suscribe")}
                      </button>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      onClick={() => {
                        navigate("/login");
                      }}
                      className="text-lg font-semibold text-center cursor-pointer hover:text-red-300"
                    >
                      {t("SuscriptionClub.NeedsLogin")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="text-black card card-pricing card-raised card-plain">
            <div className="card-body ">
              <div>
                <div
                  style={{
                    height: "80px",
                  }}
                >
                  <h5 className="card-category text-titulo-gratis">
                    {t("Suscriptions.suscriptionFree")}
                  </h5>
                  <div className="flex flex-row items-center justify-center">
                    <small className="text-base font-medium text-black">
                      USD
                    </small>
                    <small className="text-xl font-semibold text-black mx-">
                      0,00
                    </small>
                    <small className="text-base font-medium text-black">
                      /{t("Suscriptions.month")}
                    </small>
                  </div>
                </div>
                <div className="mt-3 row border-bottom border-top border-gray"></div>
                {planFree.map((item, index) => {
                  const isLastItem = index === planFree.length - 1;
                  return (
                    <>
                      <div
                        key={index}
                        className="flex flex-row items-center justify-start my-3"
                      >
                        <div className="flex items-center justify-center mr-4 ">
                          <FontAwesomeIcon
                            className={
                              "icono-planes-socios-" +
                              (item.include ? "ok-gratis" : "cancel")
                            }
                            icon={item.icon}
                          />
                        </div>
                        <div>
                          <p
                            className="text-planes-socios"
                            style={{
                              color: "black",
                            }}
                          >
                            {item.title}
                          </p>
                        </div>
                      </div>
                      {!isLastItem && (
                        <div className="mt-3 border-gray row border-bottom"></div>
                      )}
                    </>
                  );
                })}
                {/* <table className="table">
                  <tbody>
                    {planFree.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          style={{
                            height: "10px",
                          }}
                        >
                          <td>
                            <FontAwesomeIcon
                              className={
                                "icono-planes-socios-" +
                                (item.include ? "ok-gratis" : "cancel")
                              }
                              icon={item.icon}
                            />
                          </td>
                          <td
                            className="text-left"
                            style={{ fontWeight: "normal", fontSize: 14 }}
                          >
                            <p className="text-planes-socios">{item.title}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table> */}
              </div>
            </div>
          </div>
        </div>

      </div>

      <Modal show={popup} backdrop="static" style={{ top: "30vh" }}>
        <Modal.Body>
          <div>
            <PayPalPopUp
              selectedPlan={planSelected}
              tipoplan="subscription"
              idcliente={REACT_APP_PAYPAL_CLIENT_ID}
            />
            {/* <PayPalPopUp
                idplan="P-28D314351H008881EMNG7JTA"
                tipoplan="subscription"
                idcliente="desa"
              ></PayPalPopUp> */}
            {/* ) : (
              <PayPalPopUp
                idplan="P-7TS6228264587662WMNZNM3Q"
                tipoplan="subscription"
                idcliente=""
              ></PayPalPopUp>
            )} */}
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            justifyContent: "center",
          }}
        >
          <div>
            <button
              style={{}}
              onClick={() => {
                setPopup(false);
              }}
              className="btn btn-danger"
            >
              {t("Cookies.Cookies_button.degree_button")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={popupSuperPro} backdrop="static" style={{ top: "30vh" }}>
        <Modal.Body>
          <div>
            {STATUS_ENV ? (
              <PayPalPopUp
                idplan="P-1W3554952N274751SM5UNYLQ"
                tipoplan="subscription"
                idcliente="desa"
              ></PayPalPopUp>
            ) : (
              <PayPalPopUp
                idplan="P-7SX33647W3830091PM5UNRNI"
                tipoplan="subscription"
                idcliente=""
              ></PayPalPopUp>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            justifyContent: "center",
          }}
        >
          <div>
            <button
              style={{}}
              onClick={() => {
                setPopup(false);
              }}
              className="btn btn-danger"
            >
              {t("Cookies.Cookies_button.degree_button")}
            </button>
          </div>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}
