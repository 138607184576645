import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Get, Post } from "../consumir";
import { API_URL, API_URL_LOCAL_DEV, API_URL_DEV } from "../../env";

// const URL_API:any = process.env.REACT_APP_API_URL;

// const URL_API_DEV = process.env.REACT_APP_API_URL_DEV;
// POR LAS VIDEOS
// trae los mejores 10 videos
export const getRankingTop10Videos = async (id: string) => {
  const response = await Get(`${API_URL}/live/public/best10/` + id);
  const status = response.status;
  if (status !== 200) {
    console.log("ERROR NETWORK CONNECTION GET RANKING", status);
  }
  return response;
};

//muestra el video seleccionado en pantalla
export const getVideoOnPlay = async (id: string, values: any) => {
  const response = await Post(`${API_URL}/live/public/` + id, values);

  const status = response.status;
  if (status !== 200) {
    console.log("ERROR NETWORK CONNECTION GET VIDEO", status);
  }

  return response;
};

// cuenta los videos que hay en pantalla y da acceso a ellos.
export const countedVideoScreen = async (values: any) => {
  const response = await Post(`${API_URL}/tokens/countView/`, values);

  const status = response.status;

  if (status !== 200) {
    console.error("ERROR NETWORK CONNECTION COUNT VIDEO", status);
  }
  return response;
};
//envia los usuarios etiquetados
export const getTaggedUserOnVideo = async (id: string) => {
  const response = await Get(`${API_URL}/video/taguser/${id}`);
  const status = response.status;
  if (status !== 200) {
    console.log("ERROR NETWORK CONNECTION GET TAGGED USER", status);
  }
  return response;
};
// crea un highlight

export const createHighLightVideo = async (values: any) => {
  const response = await Post(`${API_URL}/live/highlight/`, values);
  const status = response.status;
  if (status !== 200) {
    console.error("ERROR NETWORK CONNECTION HIGHLIGHT", status);
  }
  return response;
};
