import React from 'react'
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'rsuite'
//import { APP_GOOGLE_TAG } from '../env';



const PoliciesCookies = () => {

    const navigate = useNavigate();
    const [t] = useTranslation("global");


    const handleAcceptCookies = () =>{
        localStorage.setItem('acceptCookies', 'true');

        window.dataLayer.push({
            "event": "cookies-aceptadas"
        });
    //    const tagManagerArgs = {
    //     gtmId:String(APP_GOOGLE_TAG),
    //    }
    //    TagManager.initialize(tagManagerArgs);

       setTimeout(() => {
        navigate('/');
       }, 1500);
    }

  return (
    <Row className="flex flex-col items-center justify-center w-full ml-3 mr-3 ">
        <Col xs={24} sm={24} md={24} lg={24}>
            <h1 className="my-3 text-3xl font-semibold text-center">{t("Cookies.Cookies_TitleLaw")}</h1>

            <p className="font-medium text-center from-stone-800">
            {t("Cookies.Cookies_lawInfo")} <b>{t("Cookies.Cookies_lawInfoBold")}</b>
            </p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} className="my-3">
            <h2 className="mb-4 text-2xl font-medium text-left text-red-700"> {t("Cookies.Cookies_Who'sCookies")}</h2>
            <p className="mx-3 font-medium text-left from-stone-800">
            {t("Cookies.Cookies_Who'sCookies_Info")}
            </p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} className="my-3">
            <h2 className="mb-4 text-2xl font-medium text-left text-red-700">{t("Cookies.Cookies_Who'sTypesCookies_Title")}</h2>
            <p className="mx-3 font-medium text-left from-stone-800"><u><b>{t("Cookies.Cookies_Who'sType_Analytics_Title")}:</b></u>{t("Cookies.Cookies_Who'sType_Analytics_Info")}</p>
            
            <p className="mx-3 font-medium text-left from-stone-800"><u><b>{t("Cookies.Cookies_Who'sType_Customizer_Title")}:</b></u>{t("Cookies.Cookies_Who'sType_Customizer_Info")}</p>
            
            <p className="mx-3 font-medium text-left from-stone-800"><u><b>{t("Cookies.Cookies_Who'sTypes_Publishers_Title")}:</b></u>{t("Cookies.Cookies_Who'sTypes_Publishers_Info")}</p>

            
            <p className="mx-3 font-medium text-left from-stone-800"><u><b>{t("Cookies.Cookies_Who'sType_Contemporany_Title")}:</b></u>{t("Cookies.Cookies_Who'sType_Contemporany_Info")}</p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} className="my-3">
            <h2 className="mb-4 text-2xl font-medium text-left text-red-700">{t("Cookies.Cookies_Disabled_Title")}</h2>
            <p className="mx-3 font-medium text-left from-stone-800">{t("Cookies.Cookies_Disabled_Info")}</p>
            <p className="mx-3 font-medium text-left from-stone-800">{t("Cookies.Cookies_Disabled_Info2")}</p>
            <p className="mx-3 font-medium text-left from-stone-800" >{t("Cookies.Cookies_Disabled_Info3")}</p>
            <Col xs={24} sm={24} md={24} lg={24} className="flex flex-col justify-start items-left">
            <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noopener noreferrer" className="mx-2 my-2 text-lg font-semibold text-left text-slate-800 hover:text-red-500">{t("Cookies.Cookies_Disabled_Info_Browser")}</a>
            <a href="https://support.microsoft.com/es-es/windows" target="_blank" rel="noopener noreferrer"  className="mx-2 my-2 text-lg font-semibold text-left text-slate-800 hover:text-red-500">{t("Cookies.Cookies_Disabled_Info_Browser2")}</a>
            <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" rel="noopener noreferrer"  className="mx-2 my-2 text-lg font-semibold text-left text-slate-800 hover:text-red-500">{t("Cookies.Cookies_Disabled_Info_Browser3")}</a>
            <a href="https://support.apple.com/es-es/HT201265" target="_blank" rel="noopener noreferrer"  className="mx-2 my-2 text-lg font-semibold text-left text-slate-800 hover:text-red-500">{t("Cookies.Cookies_Disabled_Info_Browser4")}</a>

            </Col>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} className="my-3">
            <h2 className="mb-4 text-2xl font-medium text-left text-red-700">{t("Cookies.Cookies_Third_Party_Title")}</h2>

            <p className="mx-3 font-medium text-left from-stone-800">
                {t("Cookies.Cookies_Third_Party_Info")}
            </p>
            <p className="mx-3 font-medium text-left from-stone-800">
            {t("Cookies.Cookies_Third_Party_Info2")}
            </p>
            <p className="mx-3 font-medium text-left from-stone-800">
                {t("Cookies.Cookies_Third_Party_Info3")}
            </p>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} className="my-3">
            <h2 className="mb-4 text-2xl font-medium text-left text-red-700">{t("Cookies.Cookies_Warning_uses_Title")}</h2>
            <p className="mx-3 font-medium text-left from-stone-800">{t("Cookies.Cookies_Warning_uses_Info")}</p>
            <p className="mx-3 font-medium text-left from-stone-800">{t("Cookies.Cookies_Warning_uses_Info2")}</p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} className="my-3">
        <h2 className="mb-4 text-2xl font-medium text-center text-red-700">{t("Cookies.Cookies_Question_uses_Title")}</h2>
        <Col xs={24} sm={24} md={24} lg={24} className="flex flex-row items-center justify-center">
            <Button className="mx-6 text-base font-medium text-center text-green-400 rounded-md ring-2 ring-green-500 hover:ring-green-600 hover:text-emerald-500" onClick={handleAcceptCookies}>
                {t("Cookies.Cookies_button.accept_button")}
            </Button>
            <Button className="mx-6 text-base font-medium text-center text-red-400 rounded-md ring-2 ring-red-500 hover:ring-red-600 hover:text-red-500" onClick={() =>{
                navigate("/");
            }}>
                {t("Cookies.Cookies_button.degree_button")}
            </Button>
        </Col>
        </Col>
    </Row>
  )
}

export default PoliciesCookies